/**
 * 日報ヘッダ
 */
import { isBlank } from '@/utilities/typing';
import User from './user';
import Work from './work';

class Report {
    report_id;
    user;
    report_date;
    works;

    constructor(properties) {
        if (isBlank(properties)) {
            properties = {};
        }

        this.report_id = properties.report_id;
        this.user = new User(properties.user);
        this.report_date = properties.report_date;

        this.works = [];
        if ('works' in properties) {
            this.works = properties.works.map((row) => new Work(row));
        }
    }

    // 合計時間
    get work_time_total() {
        return this.works.reduce((sum, row) => sum + parseFloat(row.work_time), 0);
    }

    // 月
    get report_date_month() {
        if (isBlank(this.report_date)) {
            return null;
        }

        let date = new Date(this.report_date);
        return date.getFullYear() * 100 + date.getMonth() + 1;
    }
}

export default Report;
