<template>
    <header class="page-title">
        <h1><i class="bi bi-journal-text"></i> 日報一覧</h1>
        <router-link class="btn btn-primary" :to="{name: 'ReportAdd'}"><i class="bi bi-plus"></i> 新規入力</router-link>
    </header>

    <section class="section">
        <form class="d-flex gap-3 col-10 mb-3" @submit.prevent="fetch()">
            <button type="button" class="btn btn-secondary" @click="fetchPrev()"><i class="bi bi-caret-left"></i></button>
            <form-input-month v-model="month" required></form-input-month>
            <button type="submit" class="btn btn-primary">表示</button>
            <button type="button" class="btn btn-secondary" @click="fetchNext()"><i class="bi bi-caret-right"></i></button>
            <form-select @change="fetch()" v-model="department" :options="departments" option_value="department_id" option_label="department_name" empty_option="全部門" :disabled="departments.length === 0"></form-select>
        </form>
        <table class="table table-bordered">
            <thead class="table-dark text-center">
                <tr v-if="user_loading">
                    <th></th>
                    <th v-for="i in Array(user_count)" :key="i"><text-loader :width="6"></text-loader></th>
                </tr>
                <tr v-else>
                    <th></th>
                    <th v-for="user of users" :key="user.user_id">{{ user.user_name }}</th>
                </tr>
            </thead>
            <tbody class="text-center">
                <template v-for="date of this.dates" :key="date">
                    <tr v-if="user_loading">
                        <td>{{ $helper.mdw(date) }}</td>
                        <td v-for="i in Array(user_count)" :key="i"><text-loader :width="6"></text-loader></td>
                    </tr>
                    <tr v-else>
                        <td>{{ $helper.mdw(date) }}</td>
                        <td v-for="user of users" :key="user.user_id">
                            <template v-if="report_loading">
                                <text-loader :width="6"></text-loader>
                            </template>
                            <template v-else-if="user.user_id in report_map && date in report_map[user.user_id]">
                                <button type="button" class="btn btn-link p-0" @click="view(report_map[user.user_id][date])">
                                    {{ $helper.clock(report_map[user.user_id][date].work_time_total) }}
                                </button>
                            </template>
                            <template v-else>
                                -
                            </template>
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
    </section>

    <part-view-report :report="view_report" @close="view_report = null"></part-view-report>
</template>

<script>
import FormInputMonth from '@/components/forms/FormInputMonth';
import FormSelect from '@/components/forms/FormSelect';
import TextLoader from '@/components/tools/TextLoader';
import PartViewReport from './PartViewReport'
import Report from "@/models/entities/report";
import User from "@/models/entities/user";
import Department from "@/models/entities/department";
import { datesOf, addMonth, subMonth } from '@/utilities/month'

export default {
    name: 'Report',
    components: {
        FormInputMonth,
        FormSelect,
        TextLoader,
        PartViewReport,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage',
        'showErrorMessage'
    ],
    data() {
        return {
            user_loading: true,
            report_loading: true,
            // month: thisMonth(), // computed に移動
            dates: [],
            users: [],
            user_count: 6,
            report_map: {},
            view_report: null,
            departments: [],
        }
    },
    mounted() {
        this.fetch();

        this.$http.get('/list/department')
        .then((response) => {
            this.departments = response.data.map((row) => {return new Department(row)});
        })
    },
    methods: {
        fetch() {
            this.dates = datesOf(this.month);

            this.user_loading = true;
            this.users = [];
            this.$http.get('/list/user', {params: {month_from: this.month, month_to: this.month, department: this.department}})
            .then((response) => {
                this.users = response.data.map((row) => {return new User(row)});
                this.user_count = this.users.length;
                this.user_loading = false;
            });

            this.report_loading = true;
            this.report_map = {};
            this.$http.get(`/report/month/${this.month}`, {params: {department: this.department}})
            .then((response) => {
                for (let report of response.data) {
                    let user_id = report.user.user_id;
                    if (!(user_id in this.report_map)) {
                        this.report_map[user_id] = {};
                    }
                    this.report_map[user_id][report.report_date] = new Report(report);
                }
                this.report_loading = false;
            });
        },
        fetchPrev()
        {
            this.month = subMonth(this.month, 1);
            this.fetch();
        },
        fetchNext()
        {
            this.month = addMonth(this.month, 1);
            this.fetch();
        },
        view(report)
        {
            this.view_report = report;
        }
    },
    computed: {
        month: {
            get() {
                return this.$store.state.condition.month;
            },
            set(value) {
                this.$store.commit('condition/setMonth', value);
            },
        },
        department: {
            get() {
                return this.$store.state.condition.department;
            },
            set(value) {
                this.$store.commit('condition/setDepartment', value);
            },
        },
    },
}
</script>

<style scoped>
</style>
