/**
 * 日報明細
 */
import { isBlank } from '@/utilities/typing';
import Project from './project';
import User from './user';
import Phase from './phase';
import WorkCategory from './work-category';

class Work {
    work_id;
    report_id;
    work_date;
    project;
    user;
    work_time;
    charge_time;
    phase;
    work_category;
    work_note;

    constructor(properties) {
        if (isBlank(properties)) {
            properties = {};
        }

        this.work_id = properties.work_id;
        this.report_id = properties.report_id;
        this.work_date = properties.work_date;
        this.project = new Project(properties.project);
        this.user = new User(properties.user);
        this.charge_time = properties.charge_time;
        this.work_time = properties.work_time;
        this.phase = new Phase(properties.phase);
        this.work_category = new WorkCategory(properties.work_category);
        this.work_note = properties.work_note;
    }
}

export default Work;
