<template>
    <div class="offcanvas offcanvas-lg offcanvas-end show" style="visibility: visible;" v-if="view_report">
        <div class="offcanvas-header">
            <h3 class="offcanvas-title">{{ view_report.user?.user_name }} さん - {{ dateMdw(view_report.report_date) }}</h3>
            <router-link
                class="btn btn-outline-primary ms-5 me-auto"
                :to="{name: 'ReportEdit', params: {report: view_report.report_id}}"
                v-if="report.user.user_id === $store.state.auth.user_id || $store.getters['auth/canEditOthersReport']()"
            >
                <i class="bi bi-pencil"></i> 編集
            </router-link>
            <button type="button" class="btn-close text-reset" @click="$emit('close')"></button>
        </div>
        <div class="offcanvas-body">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>案件</th>
                        <th>時間</th>
                        <th>(請求)</th>
                        <th>フェーズ/カテゴリ</th>
                        <th>作業内容</th>
                    </tr>
                </thead>
                <tbody v-if="loading">
                    <tr v-for="i in Array(view_report.works.length)" :key="i">
                        <td><text-loader :width="14"></text-loader></td>
                        <td class="text-center"><text-loader :width="4"></text-loader></td>
                        <td class="text-center"><text-loader :width="4"></text-loader></td>
                        <td><text-loader :width="10"></text-loader></td>
                        <td><text-loader :width="14"></text-loader></td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr v-for="work of view_report.works" :key="work.work_id">
                        <td>{{ work.project?.project_name }}</td>
                        <td class="text-center">{{ $helper.clock(work.work_time) }}</td>
                        <td class="text-center">{{ $helper.clock(work.charge_time) }}</td>
                        <td>{{ work.phase?.phase_name }}{{ work.work_category?.category_name }}</td>
                        <td>{{ work.work_note }}</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <th>合計</th>
                        <td class="text-center">{{ $helper.clock(report.work_time_total) }}</td>
                        <td colspan="3"></td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
    <div class="offcanvas-backdrop fade show" v-if="view_report" @click="$emit('close')"></div>
</template>

<script>
import { dateMdw } from '@/utilities/date-format'
import { isBlank } from '@/utilities/typing'
import Report from "@/models/entities/report";
import TextLoader from '@/components/tools/TextLoader';

export default {
    name: 'PartViewReport',
    components: {
        TextLoader,
    },
    props: {
        report: {
            type: Object
        },
    },
    data() {
        return {
            loading: false,
            view_report: null,
            dateMdw,
        }
    },
    emits: [
        'close'
    ],
    methods: {

    },
    watch: {
        report() {
            this.view_report = this.report;

            // 詳細未取得であれば取得する (works[0].project.project_name の有無で判断)
            if (this.report && this.report.report_id && this.report.works.length > 0 && isBlank(this.report.works[0].project.project_name) ) {
                this.loading = true;
                this.$http.get(`/report/${this.report.report_id}`)
                .then((response) => {
                    this.view_report = new Report(response.data);
                    this.loading = false;
                })
            }

        },
    },
}
</script>

<style scoped>

</style>
